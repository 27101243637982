import isEmpty from 'lodash/isEmpty';
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'lazysizes';

if (process.env.CLIENT) {
  require('./Image.scss'); // eslint-disable-line global-require
}

export const imageSizes = [160, 208, 320, 448, 608, 832, 1600];

const getBaseImageSrc = (imageSrc, ichefBasePath, ichefNonFoodBasePath) => {
  if (/^http/.test(imageSrc)) {
    return imageSrc;
  }

  return imageSrc.includes('food/ic')
    ? `${ichefBasePath}${imageSrc}`
    : `${ichefNonFoodBasePath}${imageSrc}`;
};

const createSrc = (imageSrc, size) => imageSrc.replace('{width}', size);

const createSrcSet = imageSrc =>
  imageSrc.includes('{width}')
    ? imageSizes.map(size => `${createSrc(imageSrc, size)} ${size}w`).join(', ')
    : undefined;

export const Image = ({
  alt,
  className,
  height,
  ichefBasePath,
  ichefNonFoodBasePath,
  isAMP,
  isLazy,
  isResponsive,
  src,
  width,
  ...props
}) => {
  const baseImageSrc = getBaseImageSrc(src, ichefBasePath, ichefNonFoodBasePath);
  const imageSrc = createSrc(baseImageSrc, 832);
  const imageSrcSet = createSrcSet(baseImageSrc);

  return (
    <div
      className={classNames(className, {
        'responsive-image-container__16/9': isResponsive,
        'placeholder-image': isEmpty(src),
      })}
      {...props}
    >
      {!isEmpty(src) &&
        (isAMP ? (
          <amp-img
            alt={alt}
            height={height || 90}
            layout={isResponsive ? 'responsive' : undefined}
            width={width || 160}
            src={createSrc(baseImageSrc, 320)}
            srcSet={imageSrcSet}
          />
        ) : (
          <>
            <img
              alt={alt}
              className={classNames({ lazyload: isLazy })}
              data-src={isLazy ? imageSrc : undefined}
              data-srcset={isLazy ? imageSrcSet : undefined}
              data-sizes={isLazy ? 'auto' : undefined}
              src={!isLazy ? imageSrc : undefined}
              srcSet={!isLazy ? imageSrcSet : undefined}
            />
            {isLazy && (
              <noscript>
                <img alt={alt} src={imageSrc} srcSet={imageSrcSet} />
              </noscript>
            )}
          </>
        ))}
    </div>
  );
};

Image.defaultProps = {
  alt: '',
  className: undefined,
  height: undefined,
  isAMP: false,
  isLazy: true,
  isResponsive: true,
  width: undefined,
};

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.string,
  ichefBasePath: PropTypes.string.isRequired,
  ichefNonFoodBasePath: PropTypes.string.isRequired,
  isAMP: PropTypes.bool,
  isLazy: PropTypes.bool,
  isResponsive: PropTypes.bool,
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
};

export default connect(
  state => ({
    isAMP: state.pageReducer.isAMP,
    ichefBasePath: state.pageReducer.ichefBasePath,
    ichefNonFoodBasePath: state.pageReducer.ichefNonFoodBasePath,
  }),
  () => ({})
)(Image);
